import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {OrderItem} from '@wix/cashier-express-checkout-widget/dist/src/types/OrderItem';
import {Shape} from '@wix/cashier-express-checkout-widget/dist/src/types/Styles';
import {PaymentAuthorizedArgs} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import {ShippingContactRestricted} from '@wix/cashier-express-checkout-widget/dist/testkit/fixtures/ShippingContactBuilder';
import {BreakdownTypes} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {ShippingContactSelectedUpdate} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
// import {cashierExpressAddressToEcomAddress} from './dupFromCart';
import loadable from '@loadable/component';
import {withPropsChangeListeners, WithPropsChangeListenersProps} from '../../../providers/listenToPropsChanges';

const CashierExpressCheckoutWidgetLazy = loadable<WidgetProps>(() =>
  import(
    /* webpackChunkName: "CashierExpressCheckoutWidget" */ '@wix/cashier-express-checkout-widget/dist/src/lazy'
  ).then((x) => x.CashierExpressCheckoutWidget)
);

@withGlobalProps
@withPropsChangeListeners
export class CashierExpressButton extends React.Component<ProvidedGlobalProps & WithPropsChangeListenersProps> {
  public getButtonStyle() {
    // const {fonts} = this.props.siteStore;

    // if (fonts.cartButton_cornersRadius) {
    //   if (fonts.cartButton_cornersRadius.value === '0px' || fonts.cartButton_cornersRadius.value === '5px') {
    //     return {shape: 'rect' as Shape, height: 42};
    //   } else {
    //     return {shape: 'pill' as Shape, height: 42};
    //   }
    // } else {
    //   return {shape: 'rect' as Shape, height: 42};
    // }
    return {shape: 'rect' as Shape, height: 42};
  }

  //istanbul ignore next: todo(eran)
  public handleCashierPaymentSubmit = async (_paymentInfo: PaymentAuthorizedArgs): Promise<any> => {
    // //here we need a volatileCart
    // this.props.cartStore.trackInitiateCheckout();
    // this.props.cartStore.sendBIEvent(BIEventsList.EWALLET_CONTINUE);
    // if (paymentInfo.error) {
    //   throw paymentInfo.error;
    // }
    //
    // if (this.shouldRequestShipping()) {
    //   await this.props.cartStore.updateAddress(//SetCartAddressesAndDestinationFromSingleAddressCommand
    //     cashierExpressAddressToEcomAddress(
    //       paymentInfo.shippingContact,
    //       paymentInfo.billingContact,
    //       this.props.cartStore.countriesCodes
    //     )
    //   );
    // } else {
    //   await this.props.cartStore.updateBillingAddress(//SetCartBillingAddressCommand -> can we reuse cartAPI?
    //     cashierExpressAddressToEcomAddress({}, paymentInfo.billingContact, this.props.cartStore.countriesCodes)
    //   );
    // }
    //
    // //CheckoutService.navigateToCheckout
    // await this.props.cartStore.handleCheckoutClick({
    //   id: 'nonFastFlow',
    //   isFastFlow: false,
    //   cashierPaymentId: paymentInfo.detailsId,
    //   isCashierExpressCheckout: true,
    // });
    // return Promise.resolve({
    //   result: 'success',
    // });
  };

  //istanbul ignore next: todo(eran)
  public handleExpressCashierShippingAddressChange = async (
    _shippingAddress: ShippingContactRestricted
  ): Promise<ShippingContactSelectedUpdate> => {
    // const {country, subdivision, zipCode} = cashierExpressAddressToEcomAddress(
    //   _shippingAddress,
    //   {},
    //   [] //this.props.cartStore.countriesCodes
    // );

    //cart-api.ts setShippingAddressesForFastFlow
    // await this.props.cartStore.setCartShippingAddressesForFastFlow(this.props.cartStore.cart.cartId, {
    //   country,
    //   subdivision,
    //   zipCode,
    // });
    //
    // await this.props.cartStore.getCart();
    //
    // const isSupportedRegion = !this.props.cartStore.cart.destinationCompleteness.includes(
    //   DestinationCompleteness.SUBDIVISION
    // );
    //
    // if (this.props.cartStore.canShipToDestination || !isSupportedRegion) {
    //   const payment = this.props.cartStore.getPaymentBreakdown();
    //   return Promise.resolve({
    //     paymentAmount: payment.total,
    //     paymentBreakdown: payment.paymentBreakdown,
    //   });
    // } else {
    //   this.props.cartStore.sendBIEvent(BIEventsList.CHECKOUT_ERROR, {
    //     field: 'destination',
    //     stage: 'paypal modal',
    //     error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE,
    //   });
    //   return Promise.resolve({error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE});
    // }
    //istanbul ignore next: todo(eran)
    return Promise.resolve({
      paymentAmount: '0',
      paymentBreakdown: {
        [BreakdownTypes.ItemsTotal]: '19.99',
        [BreakdownTypes.Shipping]: '0',
        [BreakdownTypes.Tax]: '0',
        [BreakdownTypes.Discount]: '0',
      },
    });
  };

  //istanbul ignore next: todo(Eran)
  public onClick = async () => {
    // todo(eran): results can't be tested with current cashier testKit
    this.props.globals.handleCashierOnClick();
    if (await this.props.waitForChange('handleCashierOnClickResult')) {
      return Promise.resolve({canceled: false});
    } else {
      return Promise.resolve({canceled: true});
    }
  };

  private readonly getCartItemsForCashier = (): OrderItem[] => {
    const {id, name, price} = this.props.globals.product;
    const quantity = this.props.globals.userInputs.quantity[0];
    return [{id, name, price: price.toString(), quantity}];
  };

  public render() {
    const {meta, currency, locale} = this.props.globals.cashierExpressCheckoutWidgetProps;
    const {selectedVariant, product} = this.props.globals;
    const price = (selectedVariant || product).price;

    const payment = {
      total: price.toString(),
      paymentBreakdown: {
        [BreakdownTypes.ItemsTotal]: price.toString(),
        [BreakdownTypes.Shipping]: '0',
        [BreakdownTypes.Tax]: '0',
        [BreakdownTypes.Discount]: '0',
      },
    };

    return (
      <>
        {'CashierExpressCheckoutWidgetLazy render'}
        <CashierExpressCheckoutWidgetLazy
          buttonStyle={this.getButtonStyle()}
          currency={currency}
          locale={locale}
          meta={meta}
          onClick={this.onClick}
          onPaymentAuthorized={this.handleCashierPaymentSubmit}
          // onPaymentAuthorizedInit={this.showLoader}
          onShippingContactSelected={this.handleExpressCashierShippingAddressChange}
          orderItems={this.getCartItemsForCashier()}
          paymentAmount={payment.total}
          paymentBreakdown={payment.paymentBreakdown}
          paymentLabel="forApplePay"
          requestShipping={true}
          // requestShipping={this.shouldRequestShipping()}
          withPayPalOverlay={true}
        />
      </>
    );
  }
}
